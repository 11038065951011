import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
			FitFuture
			</title>
			<meta name={"description"} content={"Тренируйся с умом, живи ярко – FitFuture, ваш ключ к здоровому будущему"} />
			<meta property={"og:title"} content={"FitFuture"} />
			<meta property={"og:description"} content={"Тренируйся с умом, живи ярко – FitFuture, ваш ключ к здоровому будущему"} />
			<meta property={"og:image"} content={"https://ciqlofun.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ciqlofun.com/img/2964522.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ciqlofun.com/img/2964522.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ciqlofun.com/img/2964522.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ciqlofun.com/img/2964522.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ciqlofun.com/img/2964522.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ciqlofun.com/img/2964522.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Text as="h1" font="--headline1" margin="0 0 24px 0">
				FAQ - Часто Задаваемые Вопросы
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Какие типы тренировок вы предлагаете?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Мы предлагаем широкий спектр онлайн-курсов, от йоги до кроссфита, адаптированных под любой уровень подготовки.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Нужно ли иметь специальное оборудование для тренировок?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Большинство наших тренировок разработаны таким образом, чтобы их можно было выполнять с минимальным набором оборудования или даже без него.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Могу ли я изменить свою тренировочную программу?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Да, вы можете в любое время обратиться к нашему тренеру для корректировки вашей программы в соответствии с изменениями ваших целей или физического состояния.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Как часто обновляются курсы?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Мы регулярно обновляем и добавляем новые курсы, чтобы предложить вам самые актуальные и эффективные методики тренировок.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Есть ли поддержка сообщества или группы?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Да, у нас есть онлайн-сообщество, где вы можете общаться с другими участниками, делиться своими успехами и находить мотивацию.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Как начать тренироваться с FitFuture?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Просто зарегистрируйтесь на нашем сайте, и вы сразу получите доступ ко всем нашим курсам и ресурсам.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline2" margin="20px 0 0 0">
			Ваше здоровье, наша страсть
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Если ваш вопрос не нашел ответа здесь, не стесняйтесь обращаться к нам. Мы готовы предоставить всю необходимую информацию, чтобы ваш опыт с FitFuture был вдохновляющим и продуктивным.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Контакты
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});